<template>
  <div class="app-container">
    <div>
      <div class="content">
        <div class="detail">
          <span class="title mr20">{{ shopname }}</span>
          <span class="mr20">摄像头编号：{{ cameraId }}</span>
          <span class="mr20">MAC-ID：{{ macid }}</span>
        </div>
        <div class="live" v-loading="loading">
          <video v-show="type === 'flv'" id="my-player" autoplay width="800" height="600">
            <source type="application/x-mpegURL" />
          </video>
          <video-stream
            v-show="type === 'rtc'"
            class="rtc-player"
            ref="video"
          ></video-stream>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flvjs from 'flv.js/dist/flv.js'
import './rtc-lib/video-stream'
import { getoptCamera } from 'src/api/legacy'

export default {
  data() {
    return {
      shopname: '',
      cameraId: this.$route.query.cameraid,
      macid: '',
      copycontent: '',
      src: '',
      sec: 60,
      loading: false,
      type: 'flv',
    }
  },
  mounted() {
    this.getoptCamera()
  },
  methods: {
    getReplayUrl(url) {
      const VIDEO_APP_ID = '1255830500'
      if (url.includes('?cameraid=')) {
        return `${url}&seek=${this.sec}`
      } else {
        return `${url}?appid=${VIDEO_APP_ID}&delay=${this.sec}`
      }
    },
    getoptCamera() {
      let data = {
        cameraid: this.cameraId || null,
      }
      this.loading = true
      getoptCamera(data)
        .then((res) => {
          if (res && res.status === 0) {
            // this.cameraId = res.data.cameraid
            this.macid = res.data.macid
            // this.src = this.getReplayUrl(res.data.boxRealtimeUrl);
            this.src = res.data.boxRealtimeUrl
            this.shopname = res.data.shopname
            if (/^wss?:\/\//.test(this.src)) {
              this.playRtc()
            } else {
              this.playFlv()
            }
          }
        })
        .then(() => {
          this.loading = false
        })
    },
    playFlv() {
      this.type = 'flv'
      // 待重构
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById('my-player')
        var flvPlayer = flvjs.createPlayer({
          type: 'flv',
          url: this.src,
        })
        flvPlayer.attachMediaElement(videoElement)
        flvPlayer.load()
        flvPlayer.play()
      }
    },
    playRtc() {
      this.type = 'rtc'
      this.$refs.video.src = this.src
    }
  },
}
</script>

<style lang="less" scoped>
.detail {
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 700;
}

.vjs-error-display.vjs-modal-dialog {
  display: none;
}
</style>
