/* eslint-disable */
import {VideoRTC} from './video-rtc.js'

/**
 * This is example, how you can extend VideoRTC player for your app.
 * Also you can check this example: https://github.com/AlexxIT/WebRTC
 */
class VideoStream extends VideoRTC {
  loadingIcon = false
  /**
   * @type {HTMLElement}
   */
  loaderEl
  /**
   * @type {HTMLElement}
   */
  statusEl
  set volume(volume) {
    this.video.volume = volume
  }
  /**
   * Custom GUI
   */
  oninit() {
    console.debug('stream.oninit')
    super.oninit()

    this.innerHTML = `
        <style>
        video-stream {
            position: relative;
        }
        .info {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 12px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
            z-index: 0;
        }
        </style>
        <div class="info">
            <div class="status"></div>
            <div class="ant-spin ant-spin-spinning" style="visibility: hidden"><span class="ant-spin-dot ant-spin-dot-spin"><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span></div>
        </div>
        `

    const info = this.querySelector('.info')
    this.loaderEl = info.querySelector('.ant-spin')
    this.statusEl = info.querySelector('.status')
    this.insertBefore(this.video, info)
  }

  showLoading() {
    if (!this.loadingIcon) return
    this.loaderEl.style.visibility = 'visible'
    this.statusEl.style.visibility = 'hidden'
    this.statusEl.innerHTML = ''
  }

  hideLoading() {
    this.loaderEl.style.visibility = 'hidden'
  }

  setStatus(text) {
    if (text && !this.isPlaying) {
      this.statusEl.style.visibility = 'visible'
      this.statusEl.innerHTML = text
    } else {
      this.statusEl.style.visibility = 'hidden'
      this.statusEl.innerHTML = ''
    }
  }

  onconnect() {
    const result = super.onconnect()
    if (result) {
      this.showLoading()
    }
    return result
  }

  ondisconnect() {
    console.debug('stream.ondisconnect')
    super.ondisconnect()
  }

  onopen() {
    console.debug('stream.onopen')
    const result = super.onopen()

    this.onmessage['stream'] = msg => {
      console.debug('stream.onmessge', msg)
      switch (msg.type) {
        case 'error':
          this.hideLoading()
          // this.setStatus(msg.value)
          break
        case 'mse':
        case 'hls':
        case 'mp4':
        case 'mjpeg':
          this.hideLoading()
          this.setStatus('')
          break
      }
    }

    return result
  }

  onclose() {
    console.debug('stream.onclose')
    return super.onclose()
  }

  onpcvideo(ev) {
    console.debug('stream.onpcvideo')
    super.onpcvideo(ev)

    if (this.pcState !== WebSocket.CLOSED) {
      // this.divMode = 'RTC';
    }
  }

}

customElements.define('video-stream', VideoStream)
